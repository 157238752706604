import React from "react";

// Customizable Area Start
import {
    Box,
    styled,
    Typography,
    Button,
    Grid,
    TextField,
    Modal,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider
} from "@material-ui/core";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import { masterCardLogo, visaLogo, amaxLogo} from "./assets"
// Customizable Area End

import SubscriptionPlanController, {
    Props,
    configJSON,
} from "./SubscriptionPlanController";

// Customizable Area Start
const tabArray = [
    { id: 1, label: "Select Plan" },
    { id: 2, label: "Payment Information" },
    { id: 3, label: "Confirmation" }
];


// Customizable Area End

export default class SubscriptionPlan extends SubscriptionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderTabs = () => {
        return (
            <Box style={webStyle.mainTabBox}>
                {tabArray.map((item, index) => (
                    <Box key={index} style={webStyle.tabItemBox}>
                        {this.state.activeTab === item.id ? (
                            <>
                                <Box
                                    style={{
                                        ...webStyle.tabCountBox,
                                        border: "1px solid #1C386D",
                                    }}
                                >
                                    <GradientText style={webStyle.tabText}>
                                        {item.id}
                                    </GradientText>
                                </Box>
                                <GradientText style={webStyle.tabText}>
                                    {item.label}
                                </GradientText>
                            </>
                        ) : (
                            <>
                                <Box
                                    style={{
                                        ...webStyle.tabCountBox,
                                        border: "1px solid #797979",
                                    }}
                                >
                                    <Typography style={{ ...webStyle.tabText, color: "#797979" }}>
                                        {item.id}
                                    </Typography>
                                </Box>
                                <Typography style={{ ...webStyle.tabText, color: "#797979" }}>
                                    {item.label}
                                </Typography>
                            </>
                        )}
                    </Box>
                ))}
            </Box>
        );
    };

    renderPlans = () => {
        const { plansList } = this.state;
        return (
            <Box style={webStyle.planMainBox}>
                {plansList.map((item, index) => (
                    <PlanItemBox key={index}>
                        <Typography className={"planName"}>{item.name}</Typography>
                        <Typography className={"planDesc"}>{item.desc}</Typography>
                        <Box className={"planPriceDuration"}>
                            <Typography className={"planPrice"}>
                                {configJSON.poundCurrency}{item.price}&nbsp;
                            </Typography>
                            <Typography className={"planDuration"}>
                                /{item.duration}
                            </Typography>
                            {item.id === 2 && (
                                <Typography className="billedText">Billed Annually</Typography>
                            )}
                        </Box>
                        <StartButton
                            data-test-id={`startButtonTestId${index}`}
                            fullWidth
                            style={webStyle.startButton}
                            onClick={() => this.handleStartNowClick(item.id)}
                        >
                            <GradientText>Get Started Now</GradientText>
                        </StartButton>
                        {item.benefits.map((item, index) => (
                            <Box key={index} className={"planBenefitItem"}>
                                <Box style={webStyle.benefitTickBox}>
                                    <CheckRoundedIcon style={webStyle.checkRoundIcon} />
                                </Box>
                                <Typography className={"planBenefitText"}>{item}</Typography>
                            </Box>
                        ))}
                    </PlanItemBox>
                ))}
            </Box>
        );
    };

    renderInput = (
            label: string,
            name: string,
            placeholder: string,
            value: string,
            handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
            error: string
        ) => {
        const { checkPayClick } = this.state;
        return (
            <Box style={webStyle.inputMainBox}>
                <Typography style={webStyle.inputLabelText}>{label}</Typography>
                <CustomTextField
                    data-test-id={`${name}InputTestId`}
                    type="text"
                    variant="outlined"
                    fullWidth
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    error={checkPayClick && error.length !== 0}
                />
            </Box>
        );
    };

    renderCardInputIcon = () => {
        return (
          <Box style={webStyle.cardLogoMainBox}>
            <Box style={webStyle.cardLogoBox}>
              <img
                style={{ width: "20px" }}
                src={masterCardLogo}
                alt="masterCardLogo"
              />
            </Box>
            <Box style={webStyle.cardLogoBox}>
              <img style={{ height: "44px" }} src={visaLogo} alt="visaLogo" />
            </Box>
            <Box style={webStyle.cardLogoBox}>
              <img style={webStyle.cardLogo} src={amaxLogo} alt="amaxLogo" />
            </Box>
          </Box>
        );
      };

    renderAddPaymentMethod = () => {
        const {
            selectedPlan,
            cardHolderName,
            formatedCardNumber,
            expDate,
            maskedCvc,
            strtAddOne,
            strtAddTwo,
            aptUnit,
            city,
            postCode,
            countryAlt,
            errCardHolderName,
            errCardNumber,
            errExpDate,
            errCvc,
            errStrtAddOne,
            errCity,
            errSelectCounty,
            errPostCode,
            errCountryAlt,
            checkPayClick
        } = this.state;
        return (
          <Box style={webStyle.mainAddPayMethoBox}>
            <Grid container style={webStyle.mainAddPayMethoWrapper}>
              <Grid item sm={8}>
                <Box style={webStyle.addPaymentLeftBox}>
                  <Typography style={webStyle.enterPaymentDetailText}>
                    Enter your payment details
                  </Typography>
                  <Divider style={{ margin: "15px 0" }} />
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            {this.renderInput(
                                "Cardholder Name",
                                "cardHolderName",
                                "Enter your name",
                                cardHolderName,
                                this.handleInputChange("cardHolderName"),
                                errCardHolderName
                            )}
                        </Grid>
                        <Grid item sm={6}>
                            <Box style={webStyle.inputMainBox}>
                                <Typography style={webStyle.inputLabelText}>
                                    Card Number
                                </Typography>
                                <CustomTextField
                                    data-test-id="cardNumberInputTestId"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter your card number"
                                    value={formatedCardNumber}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        this.handleCardNumberChange(event)
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {this.renderCardInputIcon()}
                                            </InputAdornment>
                                        )
                                    }}
                                    error={checkPayClick && errCardNumber.length !== 0}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                        {this.renderInput(
                            "Expiry Date",
                            "expDate",
                            "Enter expiry date",
                            expDate,
                            this.handleExpDateChange,
                            errExpDate
                        )}
                        </Grid>
                        <Grid item sm={6}>
                            {this.renderInput(
                                "CVC",
                                "maskedCvc",
                                "Enter CVC (Mastercard) /CVV (Visa)",
                                maskedCvc,
                                this.handleCvcChange,
                                errCvc
                            )}
                        </Grid>
                    </Grid>
                    <Typography
                        style={{
                            ...webStyle.enterPaymentDetailText,
                            marginTop: "35px"
                        }}
                    >
                        Billing Address
                    </Typography>
                    <Divider style={{ margin: "15px 0" }} />
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            {this.renderInput(
                                "Street Address 1",
                                "strtAddOne",
                                "Enter street name",
                                strtAddOne,
                                this.handleInputChange("strtAddOne"),
                                errStrtAddOne
                            )}
                        </Grid>
                        <Grid item sm={6}>
                            {this.renderInput(
                                "Street Address 2 (optional)",
                                "strtAddTwo",
                                "Enter street name",
                                strtAddTwo,
                                this.handleInputChange("strtAddTwo"),
                                ""
                            )}
                        </Grid>
                        <Grid item sm={12}>
                            {this.renderInput(
                                "Apt, unit, suit, etc.(optional)",
                                "aptUnit",
                                "",
                                aptUnit,
                                this.handleInputChange("aptUnit"),
                                ""
                            )}
                        </Grid>
                        <Grid item sm={6}>
                            {this.renderInput(
                                "City",
                                "city",
                                "Enter city",
                                city,
                                this.handleInputChange("city"),
                                errCity
                            )}
                        </Grid>
                        <Grid item sm={6}>
                            <Box style={webStyle.inputMainBox}>
                                <Typography style={webStyle.inputLabelText}>
                                    County
                                </Typography>
                                <FormControl fullWidth>
                                <CustomInputLabel
                                    shrink={false}
                                    style={{
                                    lineHeight: "0.5",
                                    display: this.state.selectCounty ? "none" : "block",
                                    }}
                                >
                                    Select county
                                </CustomInputLabel>
                                <CustomSelect
                                    data-test-id="selectCountyInputTestId"
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.selectCounty}
                                    name={"selectCounty"}
                                    onChange={this.handleSelectChange}
                                    error={checkPayClick && errSelectCounty.length !== 0}
                                >
                                    {[
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5"
                                    ].map((item: string, index: number) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                    ))}
                                </CustomSelect>
                                </FormControl>
                            </Box>
                        </Grid>
                    <Grid item sm={6}>
                        {this.renderInput(
                            "Postcode",
                            "postCode",
                            "Enter your postcode",
                            postCode,
                            this.handlePostCodeChange,
                            errPostCode
                        )}
                    </Grid>
                    <Grid item sm={6}>
                        {this.renderInput(
                            "Country",
                            "countryAlt",
                            "Enter country",
                            countryAlt,
                            this.handleInputChange("countryAlt"),
                            errCountryAlt
                        )}
                    </Grid>
                  </Grid>
                    <OkButton
                        data-test-id="backButtonTestId"
                        style={webStyle.backButton}
                        onClick={this.handleBackClick}
                    >
                        {configJSON.back}
                    </OkButton>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box style={{ padding: "28px" }}>
                  <Typography style={webStyle.selectedPlanName}>
                    {selectedPlan.name}
                  </Typography>
                  <Typography style={webStyle.selectedPlanDesc}>
                    {selectedPlan.desc}
                  </Typography>
                  <Box style={webStyle.selPlanPriceBox}>
                    <Typography style={webStyle.selPlanText}>
                      Price for your plan
                    </Typography>
                    <Typography style={webStyle.selPlanText}>
                      {`£${selectedPlan.price} /${selectedPlan.duration}`}
                    </Typography>
                  </Box>
                  <Box style={webStyle.selPlanVatBox}>
                    <Typography style={webStyle.selPlanText}>VAT(20%)</Typography>
                    <Typography style={webStyle.selPlanText}>£15</Typography>
                  </Box>
                  <Divider style={webStyle.selPlanDivider} />
                  <Box style={webStyle.selPlanSubTotalBox}>
                    <GradientText style={{ fontSize: "20px" }}>
                      Subtotal
                    </GradientText>
                    <GradientText style={{ fontWeight: 700, fontSize: "20px" }}>
                      £{Number(selectedPlan.price) + 15}
                    </GradientText>
                  </Box>
                  <OkButton
                    data-test-id="payButtonTestId"
                    style={webStyle.payButton}
                    onClick={() => this.handlePayClick()}
                  >
                    Proceed to pay
                  </OkButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
    };

    renderPaymentSuccess = () => {
        return (
            <Box data-test-id="succPaymentBoxTestId" style={webStyle.succMainBox}>
                <Box style={webStyle.succMainInnerBox}>
                <SuccessTickBox>
                    <CheckRoundedIcon style={webStyle.succCheckRoundIcon} />
                </SuccessTickBox>
                <Typography style={webStyle.modHeadingText}>
                    Your payment was successfully processed
                </Typography>
                <OkButton
                    data-test-id="loginButtonTestId"
                    fullWidth
                    style={webStyle.goToLoginButton}
                    onClick={()=>this.handleNavigation("AdminAuth")}
                >
                    Go to login
                </OkButton>
                </Box>
            </Box>
        );
    };

    renderModal = () => {
        return (
          <Modal open={this.state.openModal}>
            <Box style={webStyle.modMainBox}>
              <Box style={webStyle.modConfirmDeleteMainBox}>
                <Typography style={webStyle.modHeadingText}>
                  Your payment failed
                </Typography>
                <OkButton fullWidth style={webStyle.retryButton}>
                  Retry
                </OkButton>
              </Box>
            </Box>
          </Modal>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { activeTab } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyle.mainBox}>
                {this.renderTabs()}
                {activeTab === 1 && this.renderPlans()}
                {activeTab === 2 && this.renderAddPaymentMethod()}
                {activeTab === 3 && this.renderPaymentSuccess()}
                {this.renderModal()}
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PlanItemBox = styled(Box)({
    width: "300px",
    background: "white",
    padding: "30px 20px",
    borderRadius: "12px",
    "& .planName": {
        fontWeight: "700",
        fontSize: "18px",
        marginBottom: "6px",
    },
    "& .planDesc": {
        color: "#797979",
        lineHeight: "18px",
        fontSize: "14px",
        marginBottom: "20px",
    },
    "& .planPriceDuration": {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
        "& .planPrice": {
            fontSize: "30px",
        },
        "& .planDuration": {
            fontSize: "16px",
            fontWeight: 400,
        },
    },
    "& .billedText": {
        background: "#E0E0E080",
        borderRadius: "2px",
        color: "black",
        fontSize: "14px",
        padding: "4px 5px",
        marginLeft: "25px",
    },
    "& .planBenefitItem": {
        display: "flex",
        gap: "16px",
        margin: "10px 0",
        "& .planBenefitText": {
            lineHeight: "18px",
            fontSize: "14px",
        },
    },
    "&:hover": {
        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
        "& .planName": {
            color: "white",
        },
        "& .planDesc": {
            color: "#FBFBFB",
        },
        "& .planPriceDuration": {
            "& .planPrice": {
                color: "white",
            },
            "& .planDuration": {
                color: "#FBFBFB",
            },
        },
        "& .billedText": {
            background: "#384e79",
            color: "#FBFBFB",
        },
        "& .planBenefitItem": {
            "& .planBenefitText": {
                color: "#FBFBFB",
            },
        },
    },
});

const StartButton = styled(Button)({
    border: "1px solid #1C386D",
    textTransform: "none",
    borderRadius: "10px",
    height: "44px",
    background: "white",
    "&:hover": {
        background: "white",
    },
    "& .MuiTypography-root": {
        fontWeight: "bold",
    },
});

const GradientText = styled(Typography)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
});

const SuccessTickBox = styled(Box)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "25px",
    width: "45px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "18px"
});

const CustomTextField = styled(TextField)({
    height: "57px",
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#f44336 !important"
    },
    "& .MuiOutlinedInput-root": {
      height: "57px",
      borderRadius: "10px",
      width: "100%",
      color: "#202020",
      "& fieldset": {
        border: `1px solid #E0E0E0`,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E0E0E0",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderColor: "#E0E0E0",
      },
    },
    "& .MuiInputBase-input": {
      fontFamily: "Arial",
      fontSize: "16px",
    },
    "& input:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0px 1000px #FFFFFF inset",
    },
});

const CustomSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
        height: "61px",
        borderRadius: "8px",
        border: "1px solid #E0E0E0"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E0E0E0"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E0E0E0"
    },
    "&.Mui-disabled": {
      WebkitTextFillColor: "#0F172A",
      borderColor: "#E0E0E0",
      "& fieldset": {
        borderColor: "#E0E0E0 !important"
      }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    },
        "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #f44336 !important"
    }
});

  const OkButton = styled(Button)({
    background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
    borderRadius: "10px",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
  });
  
  const CustomInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#9da2a8",
    opacity: 1,
    paddingLeft: "14px",
  
    "&.Mui-focused": {
      display: "none",
      color: "#9da2a8",
    },
    "&.Mui-disabled": {
      WebkitTextFillColor: "#0F172A",
    },
  });

const webStyle = {
    mainBox: {
        width: "100%"
    },
    mainTabBox: {
        display: "flex",
        margin: "40px auto",
        width: "fit-content",
        gap: "18px"
    },
    modMainBox: {
        display: "flex",
        justifyContent: "center",
        height: "100vh"
    },
    addPaymentLeftBox: {
        padding: "28px",
        borderRight: "1px solid #E1E1E1",
    },
    enterPaymentDetailText: {
        fontWeight: 700,
        fontSize: "20px",
    },
    mainAddPayMethoBox: {
        marginTop: "30px",
        padding: "0 30px",
    },
    inputMainBox: {
        marginTop: "10px",
    },
    modHeadingText: {
        fontSize: "18px",
        fontWeight: 700,
        textAlign: "center"
    } as React.CSSProperties,
    retryButton: {
        marginTop: "20px",
        height: "48px"
    },
    goToLoginButton: {
        marginTop: "20px",
        height: "38px"
    },
    inputLabelText: {
        color: "#212529",
        fontWeight: 600,
        marginBottom: "8px",
    },
    cardLogoMainBox: {
        display: "flex",
        gap: "5px",
    },
    backButton: {
        marginTop: "23px",
        height: "54px",
        width: "180px"
    },
    succCheckRoundIcon: {
        fontSize: "30px",
        color: "#fff",
    },
    succMainBox: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    modConfirmDeleteMainBox: {
        marginTop: "100px",
        padding: "26px 46px",
        background: "white",
        borderRadius: "16px",
        height: "148px",
        width: "260px",
        boxSizing: "border-box",
    } as React.CSSProperties,
    succMainInnerBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30px",
        padding: "26px",
        background: "white",
        borderRadius: "12px",
        width: "fit-content",
        boxSizing: "border-box",
    } as React.CSSProperties,
    selectedPlanName: {
        fontWeight: 700,
        fontSize: "25px",
        marginBottom: "8px",
    } as React.CSSProperties,
    selectedPlanDesc: {
        color: "#797979",
        lineHeight: "20px",
        fontSize: "16px",
        marginBottom: "20px",
    },
    selPlanText: {
        lineHeight: "18px",
        fontSize: "16px",
        fontWeight: 700,
    },
    selPlanPriceBox: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px",
    },
    selPlanVatBox: {
        display: "flex",
        justifyContent: "space-between",
    },
    selPlanSubTotalBox: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "15px",
    },
    selPlanDivider: {
        margin: "12px 0 15px",
    },
    payButton: {
        marginTop: "15px",
        height: "48px",
        width: "100%",
    },
    cardLogoBox: {
        border: "1px solid #E1E1E1",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        width: "50px",
        height: "30px",
        overflow: "hidden",
        alignItems: "center",
    },
    cardLogo: {
        width: "24px",
        height: "20px",
    },
    mainAddPayMethoWrapper: {
        borderRadius: "12px",
        background: "white",
    },
    tabItemBox: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    tabText: {
        fontWeight: 700,
    },
    tabCountBox: {
        borderRadius: "25px",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    planMainBox: {
        display: "flex",
        justifyContent: "center",
        marginTop: "30px",
        gap: "20px"
    },
    benefitTickBox: {
        background: "#ECF2FF",
        borderRadius: "25px",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0
    } as React.CSSProperties,
    startButton: {
        marginBottom: "25px",
    },
    checkRoundIcon: {
        fontSize: "16px",
        color: "#1C386D",
    },
};
// Customizable Area End
