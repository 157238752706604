// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { userTemplateFile } from "./assets"
import { TourProps } from "../../../components/src/Tour";
import { getCookie } from "../../../components/src/Tour/ManageCookies";

export const configJSON = require("./config");


export interface Props extends TourProps {
    navigation: any;
    id: string;
    classes: any;
}
interface Industry {
    id: number;
    name: string;
    is_selected: boolean;
}
interface S {
    industryName: string;
    districtName: string;
    test: string;
    tabValue: any;
    personName: any;
    email: string;
    createUserModal: boolean;
    selectFields: [];
    getPastSurveysMessageId: any,
    rowsPerPage: number,
    page: number,
    group: [],
    userId: number,
    userGroups: number[],
    district: any,
    industry: any,
    company: any,
    errorMessage: string,
    error: boolean,
    continueModal: boolean
    uploadModal: boolean;
    uploadSuccessModal: boolean;
    uploadFailModal: boolean,
    particalUploadModal: boolean,
    uploadFailedModal: boolean,
    uploadFailError: string;
    createGroup: boolean,
    EditUserModal: boolean;
    deleteUserModal: boolean;
    adminSurvey: {
        district: string;
        industry: string;
    };
    createGroupAdd: {
        email: string;
        description: string;
    },
    districtList: any[];
    industryList: any[];
    visible: boolean;
    surveys: any;
    surveyName: string;
    groupList: any;
    userList: any;
    userCount: number;
    isGroupEdit: boolean;
    editGroupId: number
    Bulkopen: boolean;
    adminDetails: any;
    deleteGroupModal: boolean;
    groupError: {
        name: boolean;
        description: boolean
    };
    openDropdownId: number;
    loading: boolean;
    createUserSuccess: boolean;
    bulkUploadResponseArray: any[];
    bulkUploadSuccessMessage: string;
    uploadCancelState: boolean,
    previousDistrictID: number | null,
    previousIndustryID: number | null,
}
interface SS {
    id: any;
}

export default class AccountCreationController extends BlockComponent<
    Props,
    S,
    SS
> {
    getGroupDetailCallId: string = ""
    getUserListDetailsCallId: string = ""
    createUserCallId: string = ""
    updateUserCallId: string = ""
    deleteUserCallId: string = ""
    userBulkUploadCallId: string = ""
    createGroupCallId: string = ""
    editGroupCallId: string = ""
    getDistrictListCallId: string = ""
    getIndustryListCallId: string = ""
    getIndustryListEditCallId: string = ""
    addUserToGroupCallId: string = "";
    removeUserFromGroupCallId: string = "";
    deleteGroupCallId: string = ""
    userTemplateFile: any = userTemplateFile;

    tabs: any = {
        group: 0,
        user: 1,
        0: 'group',
        1: 'user',
    };

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.state = {
            industryName: '',
            districtName: '',
            test: '',
            tabValue: this.tabs[this.props.navigation.getParam("page")],
            createUserModal: false,
            createGroup: false,
            selectFields: [],
            getPastSurveysMessageId: "",
            personName: ["Group Name 1"],
            rowsPerPage: 20,
            page: 1,
            email: '',
            group: [],
            userId: 0,
            userGroups: [],
            district: '',
            industry: '',
            company: "",
            errorMessage: "",
            error: false,
            continueModal: false,
            uploadModal: false,
            uploadSuccessModal: false,
            uploadFailModal: false,
            particalUploadModal: false,
            uploadFailedModal: false,
            uploadFailError: "",
            EditUserModal: false,
            deleteUserModal: false,
            adminSurvey: {
                district: '',
                industry: '',
            },
            createGroupAdd: {
                email: '',
                description: '',
            },
            districtList: [],
            industryList: [],
            visible: false,
            surveys: [],
            surveyName: '',
            groupList: [],
            userList: [],
            userCount: 0,
            isGroupEdit: false, //true for edit; false for create
            editGroupId: 0,
            Bulkopen: false,
            adminDetails: JSON.parse(localStorage.getItem("user") || '{}'),
            deleteGroupModal: false,
            groupError: {
                name: false,
                description: false
            },
            openDropdownId: 0,
            createUserSuccess: false,
            loading: false,
            bulkUploadResponseArray: [],
            bulkUploadSuccessMessage: '',
            uploadCancelState: false,
            previousDistrictID: null,
            previousIndustryID: null,
            // Customizable Area End
        }

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.setState({ loading: false });
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
            return;
        }

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
     

        if (!apiRequestCallId || !responseJson) {
            return;
        }
        switch(apiRequestCallId) {
            case this.getGroupDetailCallId: 
                this.getGroupDetailReceive(responseJson);
                break;
            case this.editGroupCallId:
                this.handleEditGroupReceive(responseJson);
                break;
            case this.createGroupCallId:
                this.submitReceiver(responseJson);
                break;
            case this.getUserListDetailsCallId:
                this.getUserDetailsReveive(responseJson);
                break;
            case this.createUserCallId:
                this.createUserReceive(responseJson);
                break;
            case this.updateUserCallId:
                this.updateUserReceive(responseJson);
                break;
            case this.deleteUserCallId:
                this.deleteUserReceive(responseJson);
                break;
            case this.userBulkUploadCallId:
                this.userBulkUploadRecieve(responseJson);
                break;
            case this.getDistrictListCallId:
                this.getDistrictDetailsReceive(responseJson);
                break;
            case this.getIndustryListCallId:
                this.getIndustryDetailsReceive(responseJson);
                break;
            case this.getIndustryListEditCallId:
                this.getIndustryDetailsEditReceive(responseJson)
                break;
            case this.addUserToGroupCallId:
                this.addUserToGroupReceive(responseJson);
                break;
            case this.removeUserFromGroupCallId:
                this.removeUserFromGroupReceive(responseJson);
                break;
            case this.deleteGroupCallId:
                this.deleteGroupReceive(responseJson);
                break;
        }
        // runEngine.debugLog("Message Recived", message);
        // Customizable Area End

    }

    // Customizable Area Start

    async componentDidMount(): Promise<void> {
        const part = getCookie("productTour");
        const urlSearchParams = new URLSearchParams(window.location.search);
        const tab = urlSearchParams.get("tab");
        if (tab && part !== "Part three completed") {
            this.setState({ tabValue: +tab || 0 });
        }
        else if (part === "Part three completed") {
            this.setState({ tabValue: 1 });
            this.props.setIsOpen(true)
        }
        else if (part === "Part four completed") {
            this.setState({ tabValue: 0 });
            this.props.setIsOpen(true)
        }
        this.getGroupDetails();
        this.getUserDetails()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const part = getCookie("productTour");
        // if (part === "Part four completed") {
        //     this.setState({ tabValue: 1 });
        //     this.props.setIsOpen(true)
        // }
        console.log(this.props.currentStep, "fsfnkhdsaf")
        if (prevState.createGroup !== this.state.createGroup && this.state.createGroup) {
            this.props.setCurrentStep((e) => e + 1)
            this.props.setTourLoading(false);
        }
        if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep == 2) {
            this.setState(() => ({ createGroup: false }));
        }
        if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep == 2) {
            this.setState(() => ({ createGroup: false }));
        }
        if (this.props.tourLoading && part === "Part three completed") {
            this.setState({ createGroup: true });
        }
        if (this.props.tourLoading && part === "Part four completed") {
            this.setState({ createUserModal: true });
        }
        if (prevState.createUserModal !== this.state.createUserModal && this.state.createUserModal) {
            this.props.setCurrentStep((e) => e + 1)
            this.props.setTourLoading(false);
        }
        if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep == 6) {
            this.setState(() => ({ createUserModal: false }));
        }
        if (prevProps.currentStep !== this.props.currentStep && this.props.currentStep == 6) {
            this.setState(() => ({ createUserModal: false }));
        }
    }

    getGroupDetails = () => {
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getGroupApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getGroupDetailCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getUserDetails = () => {
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getUserListApiEndPoint + `?page=${this.state.page}&per_page=${this.state.rowsPerPage}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getUserListDetailsCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDistrictDetails = (userId = 0) => {
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_user/admins_districts_for_user?user_id=${userId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getDistrictListCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getIndustryDetails = (userId = 0) => {
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_user/admins_industry_sectors_for_user?user_id=${userId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getIndustryListCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getIndustryDetailsEdit = (userId = 0) => {
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getUserEndPoint}?id=${userId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        this.getIndustryListEditCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getGroupDetailReceive = (responseJson: any) => {
        if (responseJson?.groups) {
            this.setState({ groupList: responseJson.groups });
        }
    }

    getUserDetailsReveive = (responseJson: any) => {
        if (responseJson?.users) {
            this.setState({ userList: responseJson.users });
            this.setState({ userCount: responseJson.total_count })
        }
    }

    getDistrictDetailsReceive = (responseJson: any) => {
        if (responseJson?.districts) {
            this.setState({
                districtList: responseJson.districts
                // Sorting is removed to reflect the order in which data is received from the API. This is intentional to beacuse in frotend there is inconsitency so the sorting is done by backend	
            });
        }
    }

    getIndustryDetailsReceive = (responseJson: any) => {
        if (responseJson?.industry_sectors) {
            this.setState({
                industryList: responseJson.industry_sectors
                // Sorting is removed to reflect the order in which data is received from the API. This is intentional to beacuse in frotend there is inconsitency so the sorting is done by backend	

            });
        }
    }
    getIndustryDetailsEditReceive = (responseJson: any) => {
        if (responseJson?.user) {
            this.setState({ district: responseJson.user.selected_district_user }
            )
            this.setState({ industry: responseJson.user.selected_industry_user }
            )
        }
        if (responseJson?.industries) {
            this.setState({
                industryList: responseJson.industries
                // Sorting is removed to reflect the order in which data is received from the API. This is intentional to beacuse in frotend there is inconsitency so the sorting is done by backend	

            });
        }
        if (responseJson?.districts) {
            this.setState({
                districtList: responseJson.districts
                // Sorting is removed to reflect the order in which data is received from the API. This is intentional to beacuse in frotend there is inconsitency so the sorting is done by backend	

            });
        }
    }
    handleUserPagination = (e: any, val: any) => {
        // this.setState({ page: val }, this.getUserDetails)
    }

    handleCheck = (event: any) => {
        this.setState({ userGroups: event.target.value as number[] });
    };

    handleOpenEdit = (user: any) => {
        // this.getIndustryDetails(user.user_id);
        // this.getDistrictDetails(user.user_id);
        this.getIndustryDetailsEdit(user.user_id)
        this.setState({ industryName: user.industry_name });
        this.setState({ districtName: user.district_name });
        this.setState({
            EditUserModal: true,
            userId: user.user_id,
            email: user.email,
            userGroups: user.group_ids,
            company: user.company
        });
    };

    handleCloseEdit = () => {
        this.setState({
            userId: 0,
            email: "",
            userGroups: [],
            district: "",
            industry: "",
            company: "",
            EditUserModal: false
        });
    };
    handleIndustry = (industry: Industry) => {
        this.setState({ industryName: industry.name });
        this.setState({ previousIndustryID: industry.id });
    }
    handleDistrictChange = (district: Industry) => {
        this.setState({ districtName: district.name });
        this.setState({ previousDistrictID: district.id });
    }

    handleUserEditSubmit = () => {
        const authToken = localStorage.getItem('token');
        const data = {
            group_ids: this.state.userGroups,
            company: this.state.company,
            industry_sector_id: this.state.previousIndustryID,
            district_id: this.state.previousDistrictID,
            industry_name: this.state.industryName,
            district_name: this.state.districtName,

            selected_district_user: this.state.district,
            selected_industry_user: this.state.industry,
        };
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateUserApiEndPoint + `?id=${this.state.userId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        this.updateUserCallId = requestMessage.messageId;
        this.setState({ loading: true });
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateUserReceive = (responseJson: any) => {
        if (responseJson.message) {
            this.setState({
                email: "",
                userGroups: [],
                userId: 0,
                district: "",
                company: "",
                industry: "",
                EditUserModal: false
            });
            this.getUserDetails();
        }

        if (responseJson.errors) {
            this.setState({ error: true, errorMessage: responseJson.errors[0] })
        }
    }

    addUserToGroupReceive = (responseJson: any) => {
        if (responseJson.user) {
            this.getUserDetails();
        }
    }

    removeUserFromGroupReceive = (responseJson: any) => {
        if (responseJson.message || responseJson.user) {
            this.getUserDetails();
        }
    }

    handleSelectClose = () => {
        this.setState({ openDropdownId: 0 })
    }

    handleSelectOpen = (dropdownId: number) => {
        this.setState({ openDropdownId: dropdownId });
    }

    toggleUserInGroup = (userId: any, groupId: any, event: any) => {
        if (event.target.checked) {
            this.addUserToGroup(userId, groupId);
        } else {
            this.removeUserFromGroup(userId, groupId)
        }
    }

    addUserToGroup = (userId: any, groupId: any) => {

        const authToken = localStorage.getItem('token');
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.addUserToGroupEndPoint + `?id=${userId}&group_id=${groupId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        this.addUserToGroupCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    removeUserFromGroup = (userId: any, groupId: any) => {
        const authToken = localStorage.getItem('token');
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeUserFromGroupApiEndPoint + `?id=${userId}&group_id=${groupId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        this.removeUserFromGroupCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleOpenDeleteUser = () => {
        this.setState({ deleteUserModal: true });
    };

    cancelDeleteUser = () => {
        this.setState({ deleteUserModal: false });
    };

    confirmDeleteUser = () => {
        const authToken = localStorage.getItem('token');

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteUserApiEndPoint + `?id=${this.state.userId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DeleteApiMethod
        );

        this.deleteUserCallId = requestMessage.messageId;
        this.setState({ loading: true });
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteUserReceive = (responseJson: any) => {
        if (responseJson.message) {
            this.setState({ email: "", userGroups: [], userId: 0, district: "", company: "", industry: "", EditUserModal: false, deleteUserModal: false });
            this.getUserDetails();
        }

        if (responseJson.errors) {
            this.setState({ error: true, errorMessage: responseJson.errors[0] })
        }
    }

    handleClickCreateUser = () => {
        this.setState({ email: "", userGroups: [], createUserModal: true });
    };

    handleCancelCreateUser = () => {
        this.setState({ email: "", userGroups: [], createUserModal: false });
    };

    handleCreateUserSubmit = () => {
        if (this.state.email) {
            const authToken = localStorage.getItem('token');
            const data = { email: this.state.email, group_ids: this.state.userGroups };
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: authToken
            }
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.createUserApiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(data)
            );

            this.createUserCallId = requestMessage.messageId;
            this.setState({ loading: true });
            runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
            this.setState({ error: true, errorMessage: "Missing required field" })
        }
    }

    createUserReceive = (responseJson: any) => {
        if (responseJson.message) {
            this.setState({ email: "", userGroups: [], createUserModal: false, createUserSuccess: true });
            this.getUserDetails();
            setTimeout(() => {
                this.setState({ createUserSuccess: false })
            }, 4000);
        }
        if (responseJson.errors) {
            this.setState({ error: true, errorMessage: responseJson.errors[0] })
        }
    }

    handleCreateGroup = () => {
        this.setState({
            createGroup: true, isGroupEdit: false, createGroupAdd: {
                email: "",
                description: ""
            }
        });
    };

    handleCloseGroup = () => {
        this.setState({
            createGroup: false, groupError: {
                name: false,
                description: false
            }
        });
    };

    handleSnackbarClose = () => {
        this.setState({ error: false });
    };

    handleChangeTab = (event: any, newValue: number) => {
        this.props.navigation.navigate("AccountCreation", { page: this.tabs[newValue] })
        console.log(newValue, "kjhgdasdasd")
        this.setState({ tabValue: newValue });
        if (newValue === 0) {
            this.getGroupDetails()
        }
    };

    handleGroupSubmit = () => {
        const { email, description } = this.state.createGroupAdd;

        if (!email) {
            return this.setState((prevState) => ({
                error: true,
                errorMessage: "Missing required fields",
                groupError: {
                    name: true,
                    description: prevState.groupError.description,
                },
            }));
        }

        if (!description) {
            return this.setState((prevState) => ({
                error: true,
                errorMessage: "Missing required fields",
                groupError: {
                    description: true,
                    name: prevState.groupError.name,
                },
            }));
        }


        this.setState({ error: false });
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };

        let formData = {
            name: this.state.createGroupAdd.email,
            description: this.state.createGroupAdd.description,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createGroupApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(formData)
        );
        this.createGroupCallId = requestMessage.messageId;

        this.setState({ loading: true });
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    submitReceiver = (responseJson: any) => {
        if (!responseJson || responseJson.error) {
            return this.setState({ error: true, errorMessage: responseJson.error[0] || "Something went wrong" })
        }
        this.setState({
            createGroupAdd: {
                email: "",
                description: ""
            }
        });
        this.handleCloseGroup()
        this.getGroupDetails()


    }

    handleChangeCreateGroup = (event: any) => {
        const { name, value } = event.target;

        if (name === "email") {
            this.setState((prevState) => ({
                groupError: {
                    name: false,
                    description: prevState.groupError.description
                }
            }))
        }

        if (name === "description") {
            this.setState((prevState) => ({
                groupError: {
                    description: false,
                    name: prevState.groupError.name
                }
            }))
        }

        this.setState((prevState) => ({
            createGroupAdd: {
                ...prevState.createGroupAdd,
                [name]: value,
            },
        }));
    };

    handleChange = (event: any) => {
        const { name, value } = event.target;
        this.setState({
            adminSurvey: {
                ...this.state.adminSurvey,
                [name]: value
            }
        });
    };

    handleUploadUserClick = () => {
        this.setState({ uploadModal: true })
    }

    handleUpoadUserCancel = () => {
        this.setState({ uploadModal: false })
    }

    handleFileChange = (event: any) => {
        const file = event.target.files[0];
        if (file.size > 15 * 1024 * 1024) {
            this.setState({ uploadModal: false, uploadFailModal: true, uploadFailError: "Reason: The file size has exceeded the limit.\nFile size should be less than 15MB" })
            return;
        }
        this.setState({ uploadCancelState: true })
        const authToken = localStorage.getItem('token')

        const header = {
            // "Content-Type": configJSON.validationApiMultiPartFormData,
            token: authToken
        };

        let formData = new FormData();

        if (file) {
            formData.append("excel_file", file);
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.uploadBulkUserEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        this.userBulkUploadCallId = requestMessage.messageId;
        this.setState({ loading: true });

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    userBulkUploadRecieve = (responseJson: any) => {
        if (responseJson.message) {
            this.setState({ uploadModal: false, uploadSuccessModal: true, bulkUploadSuccessMessage: responseJson.message[0] });
            this.getUserDetails();
            this.setState({ uploadCancelState: false });
        };

        if (responseJson.status === "fail") {
            this.setState({ uploadModal: false, uploadFailedModal: true, bulkUploadResponseArray: responseJson.errors })
            this.setState({ uploadCancelState: false });
        }
        if (responseJson.status === "partially_successful") {
            this.setState({ uploadModal: false, particalUploadModal: true, bulkUploadResponseArray: responseJson.errors });
            this.getUserDetails();
            this.setState({ uploadCancelState: false });
        }
    }

    handleClickUploadSuccessContinue = () => {
        this.setState({ uploadSuccessModal: false });
        this.getUserDetails();
    };

    handleClickUploadUserFailTryAgain = () => {
        this.setState({ uploadFailModal: false, uploadModal: true, uploadFailError: "", particalUploadModal: false });
    }

    handleClickUploadUserFailCancel = () => {
        this.setState({ uploadFailModal: false, uploadFailError: "", particalUploadModal: false });
        this.setState({ uploadFailedModal: false })
    }

    handleSelectUsersForGroup = (groupId: number) => {
        this.props.navigation.navigate("GroupUsers", { groupId });
    };

    handleEditGroup = (ev: React.MouseEvent<HTMLElement>, name: string, description: string, groupId: number) => {
        ev.stopPropagation();
        ev.preventDefault();
        this.setState({
            createGroup: true, createGroupAdd: {
                email: name,
                description
            }, isGroupEdit: true, editGroupId: groupId
        });
    };

    handleGroupEdit = () => {
        const { description, email } = this.state.createGroupAdd;

        if (!email) {
            return this.setState((prevState) => ({
                error: true,
                errorMessage: "Missing required fields",
                groupError: {
                    name: true,
                    description: prevState.groupError.description,
                },
            }));
        }

        if (!description) {
            return this.setState((prevState) => ({
                error: true,
                errorMessage: "Missing required fields",
                groupError: {
                    description: true,
                    name: prevState.groupError.name,
                },
            }));
        }
        this.setState({ error: false });
        const authToken = localStorage.getItem('token')

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        };

        let formData = {
            name: this.state.createGroupAdd.email,
            description: this.state.createGroupAdd.description,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateGroupApiEndPoint + `?group_id=${this.state.editGroupId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateGroupApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(formData)
        );
        this.editGroupCallId = requestMessage.messageId;

        this.setState({ loading: true });
        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handleEditGroupReceive = (responseJson: any) => {
        if (!responseJson || responseJson.errors) {
            return this.setState({ error: true, errorMessage: responseJson.errors[0] || "Something went wrong" })
        }

        this.setState({
            createGroupAdd: {
                email: "",
                description: ""
            },
        });
        this.handleCloseGroup()
        this.getGroupDetails()

    }

    handleOpenDeleteGroupModal = (ev: React.MouseEvent<HTMLElement>, groupId: number) => {
        ev.stopPropagation();
        ev.preventDefault();
        this.setState({ deleteGroupModal: true, editGroupId: groupId });
    };

    closeDeleteGroupModal = () => {
        this.setState({ deleteGroupModal: false });
    };

    confirmDeleteGroup = () => {
        const authToken = localStorage.getItem('token');

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: authToken
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteGroupApiEndPoint + `?group_id=${this.state.editGroupId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DeleteApiMethod
        );

        this.deleteGroupCallId = requestMessage.messageId;
        this.setState({ loading: true });
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteGroupReceive = (responseJson: any) => {
        if (responseJson.message) {
            this.setState({ editGroupId: 0, deleteGroupModal: false });
            this.getGroupDetails();
        }

        if (responseJson.errors) {
            this.setState({ error: true, errorMessage: responseJson.errors[0] })
        }
    }



    handleClickContinue = () => {
        this.setState({ continueModal: true, Bulkopen: false });
    };



    // Customizable Area End
}

// Customizable Area End