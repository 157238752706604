import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"

// Customizable Area Start
import moment from "moment";
interface EventType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    respondents: number;
    start_date: string | Date;
    end_date: string | Date;
    status: string;
    individual_user_group: number;
    draft: null | string;
    start_time: string;
    end_time: string | Date;
    start: string | Date;
    end: string | Date;
    groups: number[];
    user_first_name: string;
    user_last_name: string;
    company: string;
  };
  title: string;
  start: string;
  end: string;
  status: string;
};

interface SurveyAttributes {
  name: string;
  start_date: string;
  end_date: string;
  status: string;
}

interface SurveyData {
  attributes: SurveyAttributes;
}

interface ResponseJson {
  surveys: {
    data: SurveyData[];
  };
}
export interface User {
  id: number;
  first_name: string;
  last_name: string;
  is_in_group: boolean;
}
export interface GroupType {
  id: number;
  name: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  groupList: any[];
  userList: any[];
  isIndividualUserAdded:boolean[],
  calendarEvents: any[];
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  selectedGroupList: number[];
  selectedUsers:any[],
  selectedGroup:Array<number>;
  scheduleSurvey: {
    id?: number | string;
    selectedGroup?: Array<number>;
    selectedUser?:Array<number>;
    surveyName?: string;
    selectedStartDate?: string | Date;
    selectedStartTime?: string | Date;
    selectedEndDate?: string | Date;
    selectedEndTime?: string | Date;
  };
  individualUserGroup: number | null;
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  quickSchedule: boolean,
  isOpen: boolean;
  group: string,
  survey: string,
  startDate: string  | Date,
  startTime: string  | Date,
  endDate: string  | Date,
  endTime: string | Date,
  errors: {
    group?: string;
    survey?: string;
    startDate?: string | Date;
    startTime?: string | Date;
    endDate?: string | Date;
    endTime?: string | Date;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {

  // Customizable Area Start
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  getCalendarEventsCallId: any;
  getSurveyGroupsRequest: any;
  getSurveyUserRequest: any;
  removeUserCallId: any;
  getSurveyUserCallId: any;
  onsubmitCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      quickSchedule:false,
      userList: [],
      groupList:[],
      selectedUsers:[],
      selectedTime: 0,
      isIndividualUserAdded:[],
      calendarEvents: [{
        title: "",
        start: new Date(),
        end: new Date(),
        status: "",
      }],
      selectedGroupList: [],
      individualUserGroup: null,
      selectedGroup:[],
      scheduleSurvey: {
        id:"",
        selectedGroup: [],
        selectedUser:[],
        surveyName: '',
        selectedStartDate: '',
        selectedStartTime: '',
        selectedEndDate: '',
        selectedEndTime: ''
      },
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      isOpen: false,
      group: "",
      survey: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      errors: {},
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token
      })
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {

        this.setState({ serviceProviderSchedule: [] });

        if (typeof responseJson.errors === 'string') {
          this.showAlert("", responseJson.errors)
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
      else if (apiRequestCallId != null) {

        if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          this.setState({
            serviceProviderSchedule: responseJson && responseJson.data ? responseJson.data : ''
          });
        }
        else if(apiRequestCallId === this.getCalendarEventsCallId){
          this.handleGetCalendarResponse(responseJson)
        }
        else if(apiRequestCallId === this.getSurveyUserRequest){
          this.handleUserResponse(responseJson)
        }
        else if(apiRequestCallId === this.getSurveyGroupsRequest){
          this.handleGroupResponse(responseJson)
        }
        else if (apiRequestCallId === this.removeUserCallId) {
          this.handleUserRemoveResponse(responseJson)
        }
        else if (apiRequestCallId === this.getSurveyUserCallId){
          this.handleUserResponse(responseJson);
        }
        else if (apiRequestCallId === this.onsubmitCallId){
          this.handleEditResponse(responseJson);
        }
        if (apiRequestCallId === this.serviceProviderDetailApiId) {
          let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
          msg.addData(
            getName(MessageEnum.CalendarProviderDetailsResponseMessage),
            responseJson
          );
          this.send(msg);
          this.unsubscribeMessages();
          return;
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({ ...CalendarProviderDetailsApiMessage, setApiCallId: 'serviceProviderDetailApiId' });
        return;

      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getCalendarEvents();
    this.getGroups();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any) {
    const token = this.state.token || '';
    if (profileData && profileData.id && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    } else if (this.state.serviceProviderId && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    }
  }
  
  handleOpenMoadal = () => {
    this.setState({ isOpen: true })
  }

  validateForm = () => {
    interface errors {
      selectedGroup?: string
      startDate?: string
      startTime?: string
      endDate?: string
      endTime?: string
    };
    const { selectedGroup, startDate, startTime, endDate, endTime } = this.state;
    let errors: errors = {};
    if (!selectedGroup) errors.selectedGroup = "Group selection is required";
    if (!startDate) errors.startDate = "Start date is required";
    if (!startTime) errors.startTime = "Start time is required";
    if (!endDate) errors.endDate = "End date is required";
    if (!endTime) errors.endTime = "End time is required";
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  formatTimeTo24Hour = (time: string | Date) => {
    if (!time) return "";
    return moment(time, "h:mm A").format("HH:mm"); 
  };

  handleSubmit = () => {
    if (!this.validateForm())
      return
    const { startTime, endTime } = this.state;

    const authToken = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };
    const endUTC = moment(`${this.state.endDate} ${this.state.endTime}`, "YYYY-MM-DD HH:mm").utc();
    const startUTC = moment(`${this.state.startDate} ${this.state.startTime}`, "YYYY-MM-DD HH:mm").utc();
    let body = {
      group_ids: this.state.selectedGroup,
      end_date: endUTC?.format("YYYY-MM-DD"),
      end_time: this.formatTimeTo24Hour(endTime),
      start_date: startUTC?.format("YYYY-MM-DD"),
      start_time: this.formatTimeTo24Hour(startTime),
      is_activated:`${this.state.quickSchedule}`,
    };

    if (this.state.quickSchedule) {
      const current = moment();
      body.start_date = current.format('YYYY-MM-DD')
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editLiveSurvey}${this.state.scheduleSurvey.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.onsubmitCallId = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleInputChange = (field: string, value: any) => {
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: { ...prevState.errors, [field]: "" }
    }));
  };

  getCalendarEvents = () => {
    this.setState({ calendarEvents: [{
      title: "",
      start: new Date(),
      end: new Date(),
      status: "",
    }]})
    const authToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCalendar
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getCalendarEventsCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetCalendarResponse=(
    responseJson:ResponseJson
  )=>{
    let tempEvents=responseJson.surveys.data.map((val: any) => ({
      ...val,
      title: val.attributes.name,
      start:new Date(val.attributes.start_date?.split('T')?.[0]),
      end:new Date(val.attributes.end_date?.split('T')?.[0]),
      end_calendar: moment(val.attributes.end_date?.split('T')?.[0])?.add(1, 'days').toDate(),
      status: val.attributes.status
  }))

  this.setState({
    calendarEvents:tempEvents
  })
  }

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      scheduleSurvey: {
        ...prevState.scheduleSurvey,
        [name]: value,
      },
    }));
  };

  userRender = (
    value: unknown
  ): React.ReactNode => {
    if (!Array.isArray(value)) return null;
    return value
      .map((id) => this.state.userList.find((user) => user.id === id)?.first_name)
      .filter((name): name is string => name !== undefined)
      .join(", ");
  };

  handleSelectedGroupList = () => {
    this.setState((prevState) => {
      const individualUserGroupId = prevState.individualUserGroup;
      const selectedGroup = prevState.scheduleSurvey.selectedGroup || [];
  
      const filteredGroupList = selectedGroup.filter(
        (group: any) => group !== individualUserGroupId
      );
  
      return { selectedGroupList: filteredGroupList };
    });
  };

  getGroups = () => {
    const authToken = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroup
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getSurveyGroupsRequest = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGroupResponse = (response: any) => {
    if (response.groups) {
      const sortedGroups = response.groups.sort((a: any, b: any) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else if (a.name) {
          return -1;
        } else if (b.name) {
          return 1;
        }
        return 0;
      });
      this.setState({ groupList: sortedGroups });
    }
  }


  removeUsers = (id: number) => {
    const individualUserGroupId = this.state.individualUserGroup;
    const authToken = localStorage.getItem('token');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const requestBody = {
      id: id,
      group_id: individualUserGroupId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeUserGroupApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    this.removeUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserRemoveResponse = (response: any) => {
    if (response) {
      this.getUserDetails();
    }
  }

  addUsers = (id: number) => {
    const individualUserGroupId = this.state.individualUserGroup;
    const authToken = localStorage.getItem('token');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const requestBody = {
      id: id,
      group_id: individualUserGroupId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selectUserGroupApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    this.removeUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleMouseDown(user: any) {
    if (user.is_individual_user_added) {
      this.removeUsers(user.id);
    } else {
      this.addUsers(user.id);
    }
  }

  getUserDetails = () => {
    const authToken = localStorage.getItem('token');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };

    const requestBody = {
      group_ids: this.state.selectedGroupList,
      individual_user_group: this.state.individualUserGroup
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSurveyUsersEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    this.getSurveyUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUserResponse = (response: any) => {
    if (response.users) {
      const sortedUsers = response.users.sort((a: any, b: any) => {
        if (a.first_name && b.first_name) {
          return a.first_name.localeCompare(b.first_name);
        } else if (a.first_name) {
          return -1;
        } else if (b.first_name) {
          return 1;
        }
        return 0;
      });
      this.setState({ userList: sortedUsers });
    }
  }

  handleCheckUser = (event : any)=>{
    const { value} = event.target;
    this.setState({ selectedUsers: value });
  }

  handleEditResponse = (responseJson: any) => {
    if(responseJson.data){
      this.setState({isOpen:false});
      this.getCalendarEvents();
    }
  }

  handleCheck = (event: any) => {
    const { value } = event.target;
    this.setState({ selectedGroup: value });
  };

  groupRender = (
    value: unknown
  ):
    React.ReactNode => {
    if (!Array.isArray(value)) return null;
    return value
      .map((id) => this.state.groupList.find((group) => group.id === id)?.name)
      .filter((name): name is string => name !== undefined)
      .join(", ");
  };

  updateIndividualUserAddedAndSelectedUsers = () => {
    const individualUserAddedArray = this.state.userList.map(user => user.is_individual_user_added);
    const initialSelectedUsers = this.state.userList
      .filter(user => user.is_in_group || user.is_individual_user_added)
      .map(user => user.id);

    this.setState({ 
      isIndividualUserAdded: [...individualUserAddedArray],
      selectedUsers: initialSelectedUsers,
    });
  }

  quickScheduleHandler = () => {
    this.setState((prevState) => ({ ...prevState, quickSchedule: !prevState.quickSchedule }))
  }

  formatTimeTo12Hour =
    (time: string | Date) => {
      if (!time)
        return "";
      return moment(time, "HH:mm").format("h:mm A");
    };

  getCalendarEventData=(event:EventType)=>{
    this.setState({
      scheduleSurvey:{
      id:event.id,
      selectedGroup: event.attributes.groups,
      selectedStartDate: event.start ? moment(event.start).format("YYYY-MM-DD") : "",
      selectedStartTime: this.formatTimeTo12Hour(event.attributes.start_time) || "",
      selectedEndDate: event.end ? moment(event.end).format("YYYY-MM-DD") : "",
      selectedEndTime: this.formatTimeTo12Hour(event.attributes.end_time) || "",
    },
    individualUserGroup:event.attributes.individual_user_group,
    selectedGroup:event.attributes.groups,
    startTime: this.formatTimeTo12Hour(event.attributes.start_time) || "",
    endTime: this.formatTimeTo12Hour(event.attributes.end_time) || "",
    endDate: event.end ? moment(event.end).format("YYYY-MM-DD") : "",
    startDate: event.start ? moment(event.start).format("YYYY-MM-DD") : "",
    quickSchedule:false,
  })
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
    if (prevState.userList !== this.state.userList) {
      this.updateIndividualUserAddedAndSelectedUsers();
    }
    if (
      prevState.selectedGroupList !== this.state.selectedGroupList ||
      prevState.individualUserGroup !== this.state.individualUserGroup ||
      prevState.selectedGroup !== this.state.selectedGroup
    ) {
      await Promise.all([
        this.state.selectedGroupList,
        this.state.individualUserGroup,
        this.getUserDetails(),
      ]);
    }
    if (
      prevState.scheduleSurvey.selectedGroup !== this.state.scheduleSurvey.selectedGroup
    ) {
      this.handleSelectedGroupList();
    }
    if (prevState.userList !== this.state.userList) {
      this.updateIndividualUserAddedAndSelectedUsers();
    }
  }

  generateTimeSlots = (): string[] => {
    const times: string[] = [];
    const periods: string[] = ["AM", "PM"];
    periods.forEach((period: string) => {
      for (let hour: number = 1; hour <= 12; hour++) {
        times.push(`${hour}:00 ${period}`);
        times.push(`${hour}:30 ${period}`);
      }
    });
    return times;
  };

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    return true;
  }

  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === 'serviceProviderDetailApiId') {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === 'serviceProviderScheduleApiId') {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };
  // Customizable Area End
}