import React, { useEffect } from 'react'
import { StepType, useTour } from './Tour'
import { Box, Typography } from '@material-ui/core';
import { getCookie, saveToCookie, saveToCookieDashboard } from './ManageCookies';
import ProductTourDialog from './ProductTourDialoug';
import { setStorageData } from '../../../framework/src/Utilities';

export const sampleData=[
    {
        "x_axis_data": "Jul 97",
        "submission_count": 0
    },
    {
        "x_axis_data": "Aug 97",
        "submission_count": 1
    }
];

export const sampleRes = {
    "overview": {
        "title": "Customer description.",
        "duration": 172,
        "respondents": 1,
        "group_names": [
            "dasd"
        ],
        "graph_details": [
            {
                "x_axis_data": "30 Jan",
                "user_count": 1
            }
        ]
    },
    "sections_list": [
        {
            "header": "About yourself",
            "questions": [
                {
                    "id": 11490,
                    "title": "What's your Name?",
                    "data": {
                        "type": "short-text",
                        "analytics": [],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                },
                {
                    "id": 11491,
                    "title": "What's your Gender?",
                    "data": {
                        "type": "radio",
                        "analytics": [
                            {
                                "optionId": 19318,
                                "optionName": "Male",
                                "percentage": "20.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19319,
                                "optionName": "Female",
                                "percentage": "70.0",
                                "number_of_respondents": 1
                            },
                            {
                                "optionId": 19320,
                                "optionName": "Others",
                                "percentage": "10.0",
                                "number_of_respondents": 0
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                },
                {
                    "id": 11492,
                    "title": "How old are you?",
                    "data": {
                        "type": "slider",
                        "analytics": {
                            "max_label": "end",
                            "max_value": 99,
                            "min_label": "start",
                            "min_value": 0,
                            "avg_value": 27.0,
                            "avgPercent": 27.27
                        },
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                },
                {
                    "id": 11493,
                    "title": "What's your country",
                    "data": {
                        "type": "short-text",
                        "analytics": [],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                },
                {
                    "id": 11494,
                    "title": "What's your DOB?",
                    "data": {
                        "type": "date",
                        "analytics": [],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                }
            ]
        },
        {
            "header": "Education",
            "questions": [
                {
                    "id": 11495,
                    "title": "What is the highest level of education you completed? ",
                    "data": {
                        "type": "radio",
                        "analytics": [
                            {
                                "optionId": 19321,
                                "optionName": "High School Diploma/GED",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19322,
                                "optionName": "Associate's Degree",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19323,
                                "optionName": "Bachelor's Degree",
                                "percentage": "100.0",
                                "number_of_respondents": 1
                            },
                            {
                                "optionId": 19324,
                                "optionName": "Master's Degree",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19325,
                                "optionName": "Doctoral Degree",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19326,
                                "optionName": "Other",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                },
                {
                    "id": 11496,
                    "title": "Do you currently work?",
                    "data": {
                        "type": "radio",
                        "analytics": [
                            {
                                "optionId": 19327,
                                "optionName": "Yes",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19328,
                                "optionName": "No",
                                "percentage": "100.0",
                                "number_of_respondents": 1
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                },
                {
                    "id": 11497,
                    "title": "What's your hobbies?",
                    "data": {
                        "type": "checkbox",
                        "analytics": [
                            {
                                "optionId": 19329,
                                "optionName": "Dance",
                                "percentage": "100.0",
                                "number_of_respondents": 1
                            },
                            {
                                "optionId": 19330,
                                "optionName": "Singing",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19331,
                                "optionName": "Craft",
                                "percentage": "100.0",
                                "number_of_respondents": 1
                            },
                            {
                                "optionId": 19332,
                                "optionName": "Paint",
                                "percentage": "100.0",
                                "number_of_respondents": 1
                            },
                            {
                                "optionId": 19333,
                                "optionName": "Hiking",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19334,
                                "optionName": "Running",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            },
                            {
                                "optionId": 19335,
                                "optionName": "Nothing",
                                "percentage": "0.0",
                                "number_of_respondents": 0
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                },
                {
                    "id": 11498,
                    "title": "what do you like the best ",
                    "data": {
                        "type": "ranking",
                        "analytics": [
                            {
                                "option": {
                                    "id": 19336,
                                    "name": "Going out"
                                },
                                "score": 1.0,
                                "rank": 1,
                                "user_rank": 1
                            },
                            {
                                "option": {
                                    "id": 19337,
                                    "name": "Staying In"
                                },
                                "score": 2.0,
                                "rank": 2,
                                "user_rank": 1
                            }
                        ],
                        "answer": null,
                        "date_answer": null,
                        "slider": null,
                        "option_ids": null,
                        "respondents": 1
                    }
                }
            ]
        }
    ]
}

const DashboardTour = ({ navigate }: { navigate: any }) => {
    const { setSteps, setIsOpen, setTourLoading, setCurrentStep, currentStep } = useTour();

    const steps: StepType[] = [
        {
            selector: "#main-dashboard",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        Here is all the analytics of a survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 2,
            disableScroll: true,
        },
        {
            selector: "#survey-tabs",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>From here you can switch between all the surveys</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#surver-questions",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Here are all the questions in a survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#overview-dashboard",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Here you can see basic details of the survey</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                setStorageData("dashboard", "1");
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For checkbox questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookieDashboard("2")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>

                        For multiple choice questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookieDashboard("4")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For text box and comment box questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookieDashboard("5")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For slider type questions, you will view analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookieDashboard("6")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For ranking type questions, you will see analytics like this</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookieDashboard("7")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For date type questions, you will view analytics like this,
                        the graph shows result of each date</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookieDashboard("8")
            }
        },
        {
            selector: "#dashboard-question",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        For date type questions, you will view analytics like this, the graph shows results on monthly or daily basis</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookieDashboard("9");
                saveToCookie("Part eight completed")
                setIsOpen(false)
            }
        },

    ];

    useEffect(() => {
        const part = getCookie("productTour");
        if (part === "Part six completed") {
            setSteps?.(steps);
            setCurrentStep(0);
        }
    }, [])

    return <ProductTourDialog />
}

export default DashboardTour