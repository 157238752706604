import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

const plansList = [
    {
        id: 1,
        name: "Free Trials",
        desc: "Ideal for businesses who want to explore and understand the survey tool £0 for 1 week",
        price: "0",
        duration: "For 1 week",
        benefits: [
            "Panels - Create groups for your panels",
            "Survey capabilities - 20 surveys and 500 responses",
            "User friendly - Access to users on mobile app and web interface",
            "Scheduling - Set surveys to go live in advance",
            "Analytics - View or download results in dashboard",
        ],
    },
    {
        id: 2,
        name: "Yearly subscription",
        desc: "Ideal for businesses who need access to survey tool £3,000 +VAT Billed Annually",
        price: "315",
        duration: "Year",
        benefits: [
            "Team collaboration - Additional 2 user accounts",
            "Panels - Create groups for your panels",
            "Survey capabilities - Unlimited surveys and unlimited number of responses",
            "User friendly - Access to users on mobile app and web interface",
            "Survey builder - White labeling logos when sharing surveys with users",
            "Scheduling - Set surveys to go live in advance",
            "Analytics - View or download results in dashboard",
            "API - Extract survey data for your own analysis",
        ],
    },
];

interface Plan {
    id: number;
    name: string;
    desc: string;
    price: string;
    duration: string;
    benefits: string[];
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeTab: number;
    openModal: boolean;
    selectedPlan: Plan;
    selectCounty: string;
    cardHolderName: string;
    cardNumber: string;
    formatedCardNumber: string;
    expDate: string;
    cvcNumber: string;
    maskedCvc: string;
    strtAddOne: string;
    strtAddTwo: string;
    aptUnit: string;
    city: string;
    postCode: string;
    countryAlt: string;
    plansList: Plan[];
    errCardHolderName: string;
    errCardNumber: string;
    errExpDate: string;
    errCvc: string;
    errStrtAddOne: string;
    errCity: string;
    errSelectCounty: string;
    errPostCode: string;
    errCountryAlt: string;
    checkPayClick: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscriptionPlanController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            activeTab: 1,
            openModal: false,
            selectedPlan: {
                id: 0,
                name: "",
                desc: "",
                price: "",
                duration: "",
                benefits: [],
            },
            selectCounty: "",
            cardHolderName: "",
            cardNumber: "",
            formatedCardNumber: "",
            expDate: "",
            cvcNumber: "",
            maskedCvc: "",
            strtAddOne: "",
            strtAddTwo: "",
            aptUnit: "",
            city: "",
            postCode: "",
            countryAlt: "United Kingdom",
            plansList: plansList,
            errCardHolderName: "",
            errCardNumber: "",
            errExpDate: "",
            errCvc: "",
            errStrtAddOne: "",
            errCity: "",
            errSelectCounty: "",
            errPostCode: "",
            errCountryAlt: "",
            checkPayClick: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleStartNowClick = (selectedPlanId: number) => {
        const selectedPlan = this.state.plansList.find(
          (_item, index) => selectedPlanId === index + 1
        );
    
        if (selectedPlan) {
          this.setState({
            activeTab: 2,
            selectedPlan: selectedPlan,
          });
        }
    };
    
    handlePayClick = () => {
        this.setState({ checkPayClick: true });
        const isError = this.handleErrCheck();
        if (isError === false) {
            this.setState({ activeTab: 3, checkPayClick: false });
        }
    };

    handleBackClick = () => {
        this.setState({ 
            activeTab: 1,
            selectedPlan: {
                id: 0,
                name: "",
                desc: "",
                price: "",
                duration: "",
                benefits: [],
            },
            selectCounty: "",
            cardHolderName: "",
            cardNumber: "",
            formatedCardNumber: "",
            expDate: "",
            cvcNumber: "",
            maskedCvc: "",
            strtAddOne: "",
            strtAddTwo: "",
            aptUnit: "",
            city: "",
            postCode: "",
            countryAlt: "United Kingdom",
            errCardHolderName: "",
            errCardNumber: "",
            errExpDate: "",
            errCvc: "",
            errStrtAddOne: "",
            errCity: "",
            errSelectCounty: "",
            errPostCode: "",
            errCountryAlt: "",
            checkPayClick: false
        });
    };
    
    handleInputChange =
        (prop: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
          this.setState({ [prop]: event.target.value } as unknown as Pick<
            S,
            keyof S
        >, () => this.handleErrCheck());
    };

    handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let { value } = event.target;
        if (typeof value === "string") {
            this.setState({
            ["selectCounty"]: value,
            } as unknown as Pick<S, keyof S>, () => this.handleErrCheck());
        }
    };

    handleErrCheck = () => {
        const {
            selectCounty,
            cardHolderName,
            cardNumber,
            expDate,
            cvcNumber,
            strtAddOne,
            city,
            postCode,
            countryAlt
        } = this.state;
    
        let errCardHolderName = "",
            errCardNumber = "",
            errExpDate = "",
            errCvc = "",
            errStrtAddOne = "",
            errCity = "",
            errSelectCounty = "",
            errPostCode = "",
            errCountryAlt = "";
        if (cardHolderName.length === 0) {
          errCardHolderName = "Invalid Card Holder Name";
        }
        if (cardNumber.length === 0 || cardNumber.length < 16) {
            errCardNumber = "Invalid Card Number";
        }
        if (expDate.length === 0 || expDate.length < 7) {
            errExpDate = "Invalid Expiry Date";
        }
        if (cvcNumber.length === 0 || cvcNumber.length < 3) {
            errCvc = "Invalid CVV";
        }
        if (strtAddOne.length === 0) {
            errStrtAddOne = "Invalid Street Address 1";
        }
        if (city.length === 0) {
            errCity = "Invalid City";
        }
        if (selectCounty.length === 0) {
            errSelectCounty = "Invalid Selected County";
        }
        if (postCode.length === 0 || postCode.length < 6) {
            errPostCode = "Invalid Postcode";
        }
        if (countryAlt.length === 0) {
            errCountryAlt = "Invalid Country";
        }
        let isError = {
            errCardHolderName,
            errCardNumber,
            errExpDate,
            errCvc,
            errStrtAddOne,
            errCity,
            errSelectCounty,
            errPostCode,
            errCountryAlt
        };
        this.setState({
            errCardHolderName,
            errCardNumber,
            errExpDate,
            errCvc,
            errStrtAddOne,
            errCity,
            errSelectCounty,
            errPostCode,
            errCountryAlt
        });
    
        return Object.values(isError).some((value) => value.length !== 0);
    };

    handleCardNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { cardNumber } = this.state;
        let rawValue = event.target.value;
        let value = rawValue.replace(/\D/g, "");
        let cleanMasked = rawValue.replace(/\s/g, "");
        if (cleanMasked.length < 13) {
            cleanMasked = cardNumber.slice(0, cleanMasked.length);
        } else {
            cleanMasked = cardNumber.slice(0, 12);
        }
        let newValue = cleanMasked + value;
        if (newValue.length > 16) {
            newValue = newValue.slice(0, 16);
        }
        let maskedValue = "";
        let valueLength = newValue.length;
        for (let i = 0; i < valueLength; i++) {
          if (i <= 12) {
                if (i === 4 || i === 8) {
                    maskedValue += " X";
                } else if (i === 12) {
                    maskedValue += " " + newValue[i];
                } else {
                    maskedValue += "X";
                }
          } else {
                maskedValue += newValue[i];
          }
        }
        this.setState(
            {
                cardNumber: newValue,
                formatedCardNumber: maskedValue
            },
            () => this.handleErrCheck()
        );
    };

    handleExpDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value;
        let newValue = "";
        if (inputValue.includes("/")) {
          let [month, year] = inputValue.split("/");
          let formattedYear = this.formatYear(year);
          let formattedMonth = this.formatMonth(month, formattedYear);
    
          newValue = `${formattedMonth}/${formattedYear}`;
        } else {
          if (inputValue.length > 2) {
            let [month, year] = [inputValue.slice(0, 2), inputValue.slice(2)];
            let formattedYear = this.formatYear(year);
            let formattedMonth = this.formatMonth(month, formattedYear);
    
            newValue = `${formattedMonth}/${formattedYear}`;
          } else {
            newValue = inputValue.replace(/[^\d/]/g, "");
          }
        }
        newValue = newValue.slice(0, 7);
        this.setState(
          {
            expDate: newValue,
          },
          () => this.handleErrCheck()
        );
    };
    
    formatMonth = (monthGet: string, formattedYear: string) => {
        let updateMonth = monthGet.replace(/[^\d/]/g, "");
        let month = parseInt(updateMonth, 10);
        if (month < 1 || Number.isNaN(month)) {
          month = 1;
        }
        if (month > 12) {
          month = 12;
        }
        if (formattedYear.length === 4) {
          let year = parseInt(formattedYear, 10);
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          if (year === currentYear && month < currentMonth) {
            month = currentMonth;
          }
        }
        if (month < 10) {
          return `0${month}`;
        } else {
          return `${month}`;
        }
    };
    
    formatYear = (yearGet: string) => {
        let updateYear = yearGet.replace(/[^\d/]/g, "");
        if (updateYear.length > 3) {
          let year = parseInt(updateYear, 10);
    
          const currentYear = new Date().getFullYear();
          if (year < currentYear || Number.isNaN(year)) {
            year = currentYear;
          }
          if (year > 2099 || Number.isNaN(year)) {
            year = 2099;
          }
          return `${year}`;
        }
        return `${updateYear}`;
    };
    
    handleCvcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { cvcNumber } = this.state;
        let rawValue = event.target.value;
        let value = rawValue.replace(/\D/g, "");
        let cleanMasked = rawValue.replace(/\s/g, "");
        if (cleanMasked.length < 3) {
          cleanMasked = cvcNumber.slice(0, cleanMasked.length);
        } else {
          cleanMasked = cvcNumber.slice(0, 3);
        }
        let newValue = cleanMasked + value;
        if (newValue.length > 3) {
          newValue = newValue.slice(0, 3);
        }
        let maskedValue = "";
        let valueLength = newValue.length;
        for (let i = 0; i < valueLength; i++) {
          maskedValue += "X";
        }
        this.setState(
          {
            cvcNumber: newValue,
            maskedCvc: maskedValue,
          },
          () => this.handleErrCheck()
        );
    };
    
    handlePostCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let rawValue = event.target.value;
        let newValue = rawValue.replace(/\D/g, "");
        if (newValue.length > 7) {
          newValue = newValue.slice(0, 7);
        }
        this.setState(
          {
            postCode: newValue,
          },
          () => this.handleErrCheck()
        );
    };

    handleNavigation = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          route
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    };
    // Customizable Area End
}
