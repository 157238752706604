import React, { useEffect } from 'react'
import { StepType, useTour } from './Tour'
import { Box, Typography } from '@material-ui/core';
import { getCookie, saveToCookie } from './ManageCookies';
import ProductTourDialog from './ProductTourDialoug';

const GroupTour = ({ navigate }: { navigate: any }) => {
    const { setSteps, setIsOpen, setTourLoading, setCurrentStep, currentStep } = useTour();

    const steps: StepType[] = [
        {
            selector: "#create-group",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>To create a group click here</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 0,
            disableScroll: true,
            OnNext: () => {
                setCurrentStep((prev) => prev - 1)
                setTourLoading(true);
            },
        },
        {
            selector: "#modal-group",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Enter Group Details</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#edit-icon",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Click here to edit users in a group</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 0,
            OnNext: () => {
                navigate.navigate(`GroupUser`)
            },
            disableScroll: true,
        },
        {
            selector: "#group-tour",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Here you can see the group</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                saveToCookie("Part four completed");
                navigate.navigate(`AccountCreation`)
            }
        },
        {
            selector: "#create-user",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Click here to create new user</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "left",
            padding: 10,
            disableScroll: true,
            OnNext: () => {
                setCurrentStep((prev) => prev - 1)
                setTourLoading(true);
            },
        },
        {
            selector: "#user",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>Fill the required details</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#Download-template",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>From here you can download file template for uploading users in bulk</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 1,
            disableScroll: true,
        },
        {
            selector: "#Bulk-upload",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>For adding multiple users, you can upload an excel by clicking on this button</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 1,
            disableScroll: true,
            OnNext: () => {saveToCookie("Part five completed"); navigate.navigate(`AdminSettings`); }
        },
    ];

    useEffect(() => {
        const part = getCookie("productTour");
        if (part === "Part three completed") {
            setSteps?.(steps);
            setCurrentStep(0);
            setIsOpen(false)
        } else if(part === "Part four completed"){
            setSteps?.(steps);
            setCurrentStep(4);
        }
    }, [])

    return <ProductTourDialog/>
}

export default GroupTour