import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Radio, FormControlLabel, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getCookie, saveToCookie } from './ManageCookies';
import { useTour } from './Tour';

const ProductTourDialog = () => {
    const currentPath = window.location.pathname;
    const part = getCookie("productTour");
    const [open, setOpen] = useState(true);
    const [selectedStep, setSelectedStep] = useState([0]);
    const { setIsOpen } = useTour();

    const steps = [
        "Introduction to survey",
        "To create groups",
        "To invite users",
        "Manage industry dropdown on settings and deactivate it for users",
        "To analyse results"
    ];
    useEffect(() => {
        const part = getCookie("productTour");
        if (part === "Part three completed") {
            setSelectedStep([...selectedStep, 1])
        } else if (part === "Part five completed") {
            let arr = [0, 1, 2]
            setSelectedStep([...arr])
        }
        else if (part === "Part six completed") {
            let arr = [0, 1, 2, 3]
            setSelectedStep([...arr])
        }
        else if (part === "Part eight completed") {
            let arr = [0, 1, 2, 3, 4]
            setSelectedStep([...arr])
        }
        else if (part === "Part two completed") {
            setOpen(false)
        }
        else if (part === "Part four completed") {
            setOpen(false)
        }
    }, [])

    useEffect(() => {
        if (currentPath == "/AnalyticsWeb" && selectedStep.includes(4)) {
            setOpen(false)
        }
        else if (currentPath == "/AdminSettings" && selectedStep.includes(3)) {
            setOpen(false)
        }
        else if (currentPath.startsWith("/AccountCreation") && selectedStep.includes(2)) {
            setOpen(false)
        }
        else if (currentPath == "/AdminSurvey" && selectedStep.includes(1)) {
            setOpen(false)
        }
    }, [selectedStep])
    return (
        <Dialog
            open={open}
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: "16px",
                    padding: "20px",
                    maxWidth: "500px",
                },
            }}
        >
            <DialogContent>
                <Typography variant="h6" gutterBottom>
                    You'll learn the following now
                </Typography>
                <List>
                    {steps.map((step, index) => (
                        <ListItem key={index} button>
                            <ListItemIcon>
                                {selectedStep.includes(index) ? (
                                    <CheckCircleIcon style={{ color: "#28a745" }} />
                                ) : (
                                    <Radio checked={selectedStep.includes(index)} />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={step} style={{ fontWeight: selectedStep.includes(index) ? 'bold' : 'normal' }} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between', padding: "16px" }}>
                <Button variant='outlined' onClick={() => {
                    saveToCookie("Part eight completed");
                    setOpen(false)
                }} style={{ color: "#304a7c", textTransform: "capitalize" }}>
                    Skip
                </Button>
                <Button
                    variant="contained"
                    style={{
                        background: "linear-gradient(180deg, #1C386D -0.91%, #BFCAE4 279.09%)",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "4px",
                        padding: "8px 16px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textTransform: "capitalize",
                    }}
                    onClick={() => {
                        setIsOpen(true);
                        setOpen(false);
                    }}
                >
                    Continue tour
                </Button>

            </DialogActions>
        </Dialog>
    );
};

export default ProductTourDialog;
