import React, { useEffect } from 'react'
import { StepType, useTour } from './Tour'
import { Box, Typography } from '@material-ui/core';
import { getCookie, saveToCookie } from './ManageCookies';
import ProductTourDialog from './ProductTourDialoug';

const SettingTour = ({ navigate }: { navigate: any }) => {
    const history ={};
    const { setSteps, setIsOpen, setTourLoading, setCurrentStep, currentStep } = useTour();

    const steps: StepType[] = [
        {
            selector: "#basic-1",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>
                        You can find this option in "Settings -{'>'} Basic settings"</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#industry-sector",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>To disable/enable user fields you need to click on the checkbox</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 0,
            disableScroll: true,
        },
        {
            selector: "#edit-basic",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>To edit user field click on "Edit" button</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "top",
            padding: 0,
            disableScroll: true,
            OnNext: () => {
                saveToCookie("Part five completed");
                setTourLoading(true);
                setCurrentStep((prev) => prev - 1)
            }
        },
        {
            selector: "#setting-modal",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>From here you can enable / disable options</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: true,
        },
        {
            selector: "#setting-modal",
            content: <div style={{ maxWidth: "800px", padding: "10px" }}>
                <Box>
                    <Typography>From here you can enable / disable options</Typography>
                </Box>
            </div>,
            stepInteraction: false,
            footer: () => { return "" },
            position: "bottom",
            padding: 10,
            disableScroll: false,
            OnNext: () => {
                saveToCookie("Part six completed");
                setIsOpen(false);
                location.pathname='/AnalyticsWeb';
                setIsOpen(false)
            }
        },
    ];
    useEffect(() => {
        const part = getCookie("productTour");
        if (part === "Part five completed") {
            setIsOpen(false)
            setSteps?.(steps);
            setCurrentStep(0);
        }
    }, [])

    return <ProductTourDialog />
}

export default SettingTour